// 菜单6环保
<template>
  <div class="main">
    <img
      src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fnvironment_bg_a.png"
      class="main-img"
    />
    <img
      src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fnvironment_bg_b.png"
      class="main-img"
    />
  </div>
</template>
<script>
import { usVisitLogInsert } from "@/api/home.js";

export default {
  name: "Environment",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  activated() {
    this.addVisitLogInsert();
  },
  methods: {
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Environment";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.main-img {
  width: 100%;
  display: block;
}
</style>
